<template>
  <div>
    <div class="head">
      <div class="box">
        <div>
          <div class="title"><img src="../assets/img/bbgf.png"> 帮帮共富</div>
          <ul>
            <li @click="jumpIndex">首页</li>
            <li @click="rcClick">公司</li>
            <li @click="rwClick">任务</li>
            <li>联系我们</li>
          </ul>
        </div>
        <!-- <div>
          <input type="text"
                 placeholder="账号">
          <input type="text"
                 placeholder="密码">
          <p>登录</p>
          <p>注册</p>
        </div> -->
      </div>
    </div>
    <div style="height:51px"></div>
    <div class="content">
      <div class="contHead">
        <dl>
          <dt>自行车配件</dt>
          <dd>配送方式：人才自取</dd>
          <p>有这方面经验的优先</p>
        </dl>
        <div class="img">
          <img src="https://img0.baidu.com/it/u=4077685616,688691936&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500">
        </div>
      </div>
      <div class="box">
        <h1>图文介绍</h1>
        <img src="https://img0.baidu.com/it/u=4077685616,688691936&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500">
      </div>
    </div>
    <div class="week_foot">
      <div class="week_fwp">
        <div class="week_foot-about">
          <div class="week_foot-tit"><a title="关于我们">关于我们</a><i></i></div>
          <div class="week_foot-cont">
            <p class="week_foot-contact-item"></p>
            <div class="week_foot-contact-item">
              <p class="name">公司名称：</p>
              <p class="text">路桥区科技发展有限公司</p>
            </div>
            <div class="week_foot-contact-item">
              <p class="name">公司介绍：</p>
              <p class="text">台州市路桥区科技发展有限公司成立于2007年12月11日，注册地位于浙江省台州市路桥区珠光街201号，法定代表人为陈韬。经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；人力资源服务（不含职业中介活动、劳务派遣服务）；物业管理；住房租赁；非居住房地产租赁；太阳能发电技术服务；大数据服务(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)。许可项目：劳务派遣服务；职业中介活动；建筑劳务分包(依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准)。台州市路桥区科技发展有限公司对外投资2家公司。</p>
            </div>
          </div>
        </div>
        <div class="week_foot-contact">
          <p class="week_foot-tit"><a title="联系我们">应用详情</a><i></i></p>
          <p class="week_foot-contact-item"></p>
          <div class="week_foot-contact-item">
            <p class="name">应用名称：</p>
            <p class="text">帮帮共富</p>
          </div>
          <div class="week_foot-contact-item">
            <p class="name">公司地址：</p>
            <p class="text">浙江省台州市路桥区珠光街201号</p>
          </div>
          <div class="week_foot-contact-item">
            <p class="name">应用详情：</p>
            <p class="text">帮助需要副业或者在家没事情干的人群增加额外收入</p>
          </div>
          <div class="week_foot-contact-item">
            <p class="name">服务信息：</p>
            <p class="text">保证任务的可信度，保护人的信息资料，有众多的公司资源</p>
          </div>
        </div>
      </div>
    </div>
    <div id="wk_ft">
      <div class="wk_footer"> Copyright 2022 路桥区科技发展有限公司&nbsp; 备案号：<a href="https://beian.miit.gov.cn/"
           target="_blank"
           title="浙ICP备2022018922号-1">
          浙ICP备2022018922号-1</a> <br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  methods: {
    jumpIndex () {
      this.$router.push({
        path: '/index'
      })
    },
    rwClick () {
      this.$router.push({
        path: '/rw'
      })
    },
    rcClick () {
      this.$router.push({
        path: '/rc'
      })
    },
  },
}

</script>
<style scoped>
.content {
  padding: 0 25%;
  background: white;
}

.contHead {
  width: 100%;
  display: flex;
  padding: 20px 0;
}

.contHead dl {
  width: calc(100% - 250px);
  height: 250px;
  padding-right: 20px;
  box-sizing: border-box;
}

.contHead dl dt {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 50px;
}

.contHead dl dd {
  color: #1890ff;
}

.contHead dl p {
  color: #9c9c9c;
  margin-top: 10px;
  line-height: 150%;
}

.contHead .img {
  width: 250px;
  height: 250px;
}

.contHead .img img {
  width: 100%;
  height: 100%;
}

.content .box h1 {
  font-weight: 600;
  font-size: 30px;
  margin-top: 60px;
}

.content .box img {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>